@import './src/css/variabilities';


.features {
  background: #66928e;
  padding: 0 10px 30px 10px;
  margin-top: 10px;

  .featuresContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
    @media (max-width: 780px) {
      justify-content: space-around;
    }


    .feature {
      width: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 10px;

      @media (max-width: 1045px) {
        width: 200px;
      }
      @media (max-width: 900px) {
        width: 170px;
      }
      @media (max-width: 780px) {
        width: 250px;
      }
      @media (max-width: 550px) {
        width: 200px;
      }
      @media (max-width: 470px) {
        width: 250px;
      }

      div {
        width: 100%;
        height: 200px;
        box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
        @media (max-width: 1045px) {
          height: 150px;
        }
        @media (max-width: 900px) {
          height: 130px;
        }
        @media (max-width: 780px) {
          height: 200px;
        }
        @media (max-width: 550px) {
          height: 150px;
        }
        @media (max-width: 470px) {
          height: 200px;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit:cover;
      border-radius: 5px;
      transition: all 0.5s ease;
    }
    img:hover {
      transform: scale(1.25);
      transition: all 0.5s ease;
    }
  }
  p, h3, h4 {
    color: #ffffff;
  }

  p{
    line-height: 1.5;
    text-align: center;
    font-size: 1.05rem;
  }
}