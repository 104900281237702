@import './src/css/variabilities';

.formCallback {
  width: 70vw;
  height: 70vh;
  position: fixed;
  top: 250px;
  left: 15vw;
  z-index: 3;
  @media (max-width: 1400px) {
    top: 200px;
  }
  @media (max-width: 1200px) {
    top: 150px;
  }
  @media (max-width: 450px) {
    width: 80vw;
    left: 10vw;
  }

  h4 {
    color: white;
  }

  form {
    padding: 10px;
  }
  .container {
   max-width: 100%;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    background-color:#66928e;
  }

  .callBack {
    display: flex;
    flex-direction: column;
    width: 50%;
    @media (max-width: 800px) {
      width: 70%;
    }
    @media (max-width: 550px) {
      width: 85%;
    }
  }
  input {
    margin-bottom: 5px;
    height: 40px;
    border-radius: 5px;
  }
  textarea {
    height: 130px;
    border-radius: 5px;
  }
  label{
    display: flex;
    align-items: center;
    color: white;
    input {
      margin-right: 5px;
    }
  }

  .submitButton {
    margin-bottom: 40px;
    padding: 13px 36px;
    span{
      display: flex;
      justify-content: center;
      align-items: center;
      color: #393939;
    }
  }
  & .disabled {
    background-color: gainsboro;
    border: 1px solid gainsboro;
    color: #393939;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2) inset;
    padding: 0 7px;

  }

}