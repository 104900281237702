//@import './src/css/variabilities';


.video {
  padding: 30px 30px;
  margin: auto;
  @media (max-width: 620px) {
    padding: 20px 30px;
  }
  @media (max-width: 590px) {
    padding: 15px 30px;
  }
  @media (max-width: 560px) {
    padding: 0px 30px;
  }
  @media (max-width: 520px) {
    padding: -10px 30px;
  }

  .pictureContent {
    width: inherit;
  }

  .forDesktop {
    @media (max-width: 415px) {
      display: none;
    }
  }
  .forMobile {
    margin: 40px 0 50px 0;
    .slick-dots {
      margin: -21px 0;
    }
    @media (min-width: 415px) {
      display: none;
    }
  }

  .videoContent {
    width: 100%;
    height: 50vh;
    margin: auto;
    @media (max-width: 500px) {
      height: 40vh;
    }
    @media (max-width: 440px) {
      height: 30vh;
    }
  }
  .pictureContainer {
    height: 50vh;
    @media (max-width: 500px) {
      height: 40vh;
    }
    @media (max-width: 440px) {
      height: 30vh;
    }
  }

  .picture {
    height: 100%;
    width: inherit;
    object-fit: contain;
  }

  .pictureContainer2 {
    height: 13vh;
  }
  & .forMobile {
    @media (min-width: 415px) {
      display: none;
    }
  }
  .videoContent2 {
    height: 13vh;
    width: 100%;
  }


  .picture2 {
    height: 100%;
    object-fit: contain;
  }

  .slick-slider:last-of-type {
    background: linear-gradient(90deg, rgba(102, 146, 142, 0.1) 35%, rgba(102, 146, 142, 0.55) 100%);
    border-radius: 5px;
  }

  .slick-arrow, .slick-arrow.slick-prev::before, .slick-arrow.slick-next::before {
    background-color: #c0316c;
    border-radius: 10px;
  }
}