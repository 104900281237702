$sans-pro: "Source Sans Pro";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  box-sizing: border-box;
}

html, body, p, a, img {
  margin: 0;
  padding: 0;
  font-family: $sans-pro, sans-serif;
  font-size: 16px;
  color: #393939;
  //color: #344b4a;

  @media (max-width: 1150px) {
    font-size: 12px;
  }
}



.container {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
}

a {
  display: inline-block;
  text-decoration: none;
}

h3 {
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: center;
  margin: 50px 0;

  @media (max-width: 870px) {
    margin: 20px 0;
  }

}
h4 {
  font-size: 1.3rem;
}

button {
  padding: 10px 25px;
  border-radius: 5px;
  background-color: #c0316c;
  color: white;
  border: 1.5px solid #c0316c;
  text-transform: uppercase;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
}