@import './src/css/variabilities';

.burger-menu {

  @media (min-width: 1020px) {
    display: none;
  }

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  z-index: 10;
  position: fixed;
  top: 3.5%;
  left: 0;
  background-color: #373a47;
  padding: 40px 20px;
  color: white;
  width: 70%;
  height: 140vh;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;

  &.active {
    transform: translateX(0);
  }


  .burger-link {
    a {
      color: white;
      letter-spacing: 0.2rem;
      font-size: 25px;
      text-transform: uppercase;
      font-family: $sans-pro;
      padding: 15px;
      @media (max-width: 600px) {
        font-size: 20px;
      }
      @media (max-width: 430px) {
        font-size: 17px;
      }
      @media (max-width: 380px) {
        font-size: 15px;
      }
    }
  }

  .burger-link {
    padding: 1rem;
    font-size: 1.1rem;
    letter-spacing: 0.2rem;
  }

  span {
    text-transform: lowercase;
  }

}